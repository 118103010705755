<template>
  <NuxtLayout
    name="dashboard"
    data-layout="pathfinder/pathfinder"
    class="bg-forgd-bgd-200 text-forgd-primary-900 min-h-screen font-normal font-display"
  >
    <template #header>
      <div class="flex gap-4 items-center">
        <img src="/images/solutions-pathfinder-pre-tge-token-economics-03.svg" alt="Dashboard Icon" width="45" height="45">
        <div class="text-xl font-semibold flex-shrink-0">
          Pathfinder
        </div>
      </div>
    </template>
    <div class="flex min-h-screen">
      <div class="flex-grow mx-auto h-screen">
        <slot />
      </div>
    </div>
  </NuxtLayout>
</template>
